#technologies {
    background-image: url('../../../Assets/techbg.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    filter: hue-rotate(-42deg);
}

.sub-text{
    background-color: rgba(255,255,255, 0.4);
}

.tech-list{
    filter: hue-rotate(42deg);
}