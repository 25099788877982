.heroBtn {
    background: #0DA772!important;
}

.heroBtn:hover {
    background-color: #3214C2!important;
}

.heroEmptyBtn {
    border-color: #513FCE!important;
    color: #513FCE!important;
}

.heroEmptyBtn:hover {
    border-color: #3214C2!important;
    color: #3214C2!important;
}

.typed{
    color: #3214C2!important;
}

.main-image{
    filter: hue-rotate(458deg)!important;
}